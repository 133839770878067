import { createRouter, createWebHistory } from 'vue-router';

import home from './views/home.vue';
import caseInfo from './views/case.vue';
import about from './views/about.vue';
import contact from './views/contact.vue';
import notify from './views/notify.vue';
import template from './views/template.vue';

const routes = [
    { 
        path: '/', 
        component: home,
        meta:{title:"专注中小企业定制建站-巨橙软件"} 
    },
    { 
        path: '/index.html', 
        component: home,
        meta:{title:"专注中小企业定制建站-巨橙软件"} 
    },
    { 
        path: '/case.html', 
        component: caseInfo,
        meta:{title:"经典案例-巨橙软件"} 
    },
    { 
        path: '/about.html', 
        component: about,
        meta:{title:"关于我们-巨橙软件"} 
    },
    { 
        path: '/contact.html', 
        component: contact,
        meta:{title:"招贤纳士-巨橙软件"} 
    },
    { 
        path: '/template.html', 
        component: template,
        meta:{title:"模板市场-巨橙软件"} 
    },
    { 
        path: '/notify.html', 
        component: notify,
        meta:{title:"支付完成通知-巨橙软件"} 
    },
]
  
const router = createRouter({
    history: createWebHistory(),
    routes,
  })
  
export default router
