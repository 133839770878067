<template>
  <!-- 位置 -->
  <div class="footer w-layout">
    <div class="contact-wrap">
      <div class="main-wrap">
        <div class="fl address">
          <h4>杭州总部</h4>
          <div class="cont">
            <div class="boxs" style="display: block">
              <a href="tel:0571-5625 8010" rel="noopener noreferrer"
                ><p class="stt">电话：0571-5625 8010</p></a
              >
              <a href="mailto:juchengsoft@foxmail.com" rel="noopener noreferrer"
                ><p class="stt">邮箱：juchengsoft@foxmail.com</p></a
              >
              <p style="color:#333">
                <span class="stt">地址：</span
                >杭州市滨江区浦沿街道云杉郡景中心2幢211室
              </p>
            </div>
          </div>
        </div>
        <div class="fl weixin">
          <h4>Wechat</h4>
          <div class="wx-img"><img src="../assets/img/wx.png" alt="微信咨询" /></div>
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <footer>
      <div class="main-wrap">
        <div class="top-wrap f-cb">
          <div class="fl ft-nav">
            <a href="/">首页</a>
            <span class="line"></span>
            <a href="case.html">成功案例</a>
            <span class="line"></span>
            <a href="contact.html">招贤纳士</a>
            <span class="line"></span>
            <a href="about.html">关于我们</a>
          </div>
          <span class="call fr">招聘：0571-56258010</span>
        </div>
        <div class="last-wrap">
          <p>©2019-2025 Juchengsoft All rights reserved. 浙ICP备2020043609号</p>
        </div>
      </div>
    </footer>
  </div>
  <div class="tips">
    <ul>
      <li class="call">
        <a href="tel:19550263172" class="tit">售前咨询</a>
      </li>
      <li class="service">
        <a href="tel:19550263172" class="tit">售后服务</a>
      </li>
      <li class="mail">
        <a href="mailto:juchengsoft@foxmail.com" class="tit">投递邮件</a>
      </li>
    </ul>
  </div>
</template>
