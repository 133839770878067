<template>
  <layout-header :images="images" />
  <div class="myservice">
    <div class="tit">
      <span>我们做什么</span>
      <p>我可以做企业官网、外贸网站、跨境电商平台</p>
    </div>
    <div class="content w-layout">
      <ul>
        <li>
          <div class="img">
            <img src="../assets/img/site.png" />
          </div>
          <div class="text">
            <h3>企业官网</h3>
            <p>
              给企业量身打造门户网站，帮助公司宣传产品，提高公司产品的知名度。
            </p>
          </div>
        </li>
        <li>
          <div class="img">
            <img src="../assets/img/trade.png" />
          </div>
          <div class="text">
            <h3>外贸网站</h3>
            <p>
              个人SOHO或企业借助外贸网站，轻松走上国际平台，让国外客户更方便的找到及联系到我们。
            </p>
          </div>
        </li>
        <li style="margin-right: 0px">
          <div class="img">
            <img src="../assets/img/shop.png" />
          </div>
          <div class="text">
            <h3>跨境电商平台</h3>
            <p>
              帮助企业或个人搭建跨境电商，实现跨境O2C，开拓国际市场，实现盈利目标。
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="choose w-layout">
    <div class="choose-cont">
      <h2
        class="wow fadeInUp animated"
        style="visibility: visible; animation-name: fadeInUp"
      >
        为什么选择我们
      </h2>
      <div class="choose-list">
        <ul class="f-cb">
          <li
            class="wow fadeInLeft animated"
            style="visibility: visible; animation-name: fadeInLeft"
          >
            <img
              src="../assets/img/img1.jpg"
              alt=""
            />
            <div class="bg"></div>
            <div class="box">
              <div class="ico ico1"></div>
              <h3>高性价比</h3>
              <p>
                我们会根据客户需求，提供适合客户的建站方案，支持全网对比，让高性价比落到实处。
              </p>
            </div>
          </li>
          <li
            class="wow fadeInLeft animated"
            data-wow-delay=".2s"
            style="
              visibility: visible;
              animation-delay: 0.2s;
              animation-name: fadeInLeft;
            "
          >
            <img
              src="../assets/img/img2.jpg "
              alt=""
            />
            <div class="bg"></div>
            <div class="box">
              <div class="ico ico2"></div>
              <h3>7天极速交付</h3>
              <p>
                企业官网从购买到投产，最迟7天工作日，最快当天交付使用。
              </p>
            </div>
          </li>
          <li
            class="wow fadeInLeft animated"
            data-wow-delay=".4s"
            style="
              visibility: visible;
              animation-delay: 0.4s;
              animation-name: fadeInLeft;
            "
          >
            <img
              src="../assets/img/img3.jpg"
              alt=""
            />
            <div class="bg"></div>
            <div class="box">
              <div class="ico ico3"></div>
              <h3>全托管运维</h3>
              <p>
                提供客户持续技术保障与优化，帮助节约成本、提高运营效率。
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="service-step w-layout">
    <div class="step-tit">
      <span>我们服务流程</span>
      <p>仅需6步，即刻为您定制企业网站，网站代运维免费</p>
    </div>
    <ul class="step">
      <span class="line"></span>
      <li>
        <b>01</b><img src="../assets/img/yuming.png" />
        <p>注册域名</p>
      </li>
      <li>
        <b>02</b><img src="../assets/img/server.png" />
        <p>选择网站服务器</p>
      </li>
      <li>
        <b>03</b><img src="../assets/img/dns.png" />
        <p>域名解析</p>
      </li>
      <li>
        <b>04</b><img src="../assets/img/icp.png" />
        <p>网站备案</p>
      </li>
      <li>
        <b>05</b><img src="../assets/img/https.png" />
        <p>HTTPS证书配置</p>
      </li>
      <li>
        <b>06</b><img src="../assets/img/online.png" />
        <p>网站上线</p>
      </li>
    </ul>
  </div>
  <layout-footer />
</template>

<script>
import product from "../components/product.vue";
export default {
  components: {
    product,
  },
  data() {
    return {
      images: [
        { imageUrl: "banner01.jpg" }
      ],
    };
  },
};
</script>
